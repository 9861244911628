/*.story-item_story_item__HVa_H {*/
/*    flex-shrink: 0;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    position: relative;*/
/*    width: 103px;*/
/*    height: 103px;*/
/*    padding: 4px;*/
/*    outline: none;*/
/*    text-align: unset;*/
/*    background: #0000;*/
/*    border: none;*/
/*    scroll-snap-align: start;*/
/*    -webkit-user-select: none;*/
/*    user-select: none;*/
/*    transition: transform .15s linear;*/
/*}*/

/*.story-item_story_item__HVa_H.story-item_read__NJ1Is .story-item_outline__tmW1h {*/
/*    opacity: 0;*/
/*}*/

/*.story-item_story_item__HVa_H .story-item_outline__tmW1h {*/
/*    position: absolute;*/
/*    inset: 0;*/
/*}*/

/*.story-item_content__ymY_b .story-item_image__N2mON {*/
/*    position: absolute;*/
/*    inset: 0;*/
/*    z-index: 0;*/
/*}*/

/*.story-item_story_item__HVa_H {*/
/*    flex-shrink: 0;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    position: relative;*/
/*    width: 103px;*/
/*    height: 103px;*/
/*    padding: 4px;*/
/*    outline: none;*/
/*    text-align: unset;*/
/*    background: #0000;*/
/*    border: none;*/
/*    scroll-snap-align: start;*/
/*    -webkit-user-select: none;*/
/*    user-select: none;*/
/*    transition: transform .15s linear;*/
/*}*/

/*.story-item_content__ymY_b .story-item_title__8YW3y {*/
/*    font-style: normal;*/
/*    font-weight: 500;*/
/*    font-size: 12px;*/
/*    line-height: 110%;*/
/*    padding: 10px;*/
/*    font-family: SF Pro Display, -apple-system, BlinkMacSystemFont, Roboto, sans-serif;*/
/*    letter-spacing: -.05em;*/
/*    z-index: 1;*/
/*    position: relative;*/
/*}*/

/*.story-item_content__ymY_b img {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    object-fit: cover;*/
/*}*/

.container-stories {
    overflow-x: auto;
    overscroll-behavior-x: contain;
    width: 100%;
}

.story-container {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 5rem;
    grid-gap: 30px;
}

.story-container .content {
    display: grid;
    grid-gap: 0.5rem;
    margin: 0;
    text-align: center;
    position: relative;
    cursor: pointer;
    user-select: none;
    transition: 0.2s ease-in;
    width: 100px;
    height: 100px;
}

.story-container .content .img-container {
    display: inline-block;
    aspect-ratio: 1/1;
    width: 100%;
    height: 100px;
    border-radius: 20%;
    background:
            radial-gradient(rgba(0,0,0,0.15) 30%, transparent 0),
            radial-gradient(white 65%, transparent 0),
            linear-gradient(to top right, orange, deeppink);
    padding: 4px;
    box-sizing: border-box;
}

.story-container .content .img-container-inner {
    width: 100%;
    height: 100%;
    border-radius: 20%;
    overflow: hidden; /* Обеспечиваем, что изображение не выйдет за границы */
    padding: 2px;
    background: white;
}

.story-container .content .text-container {
    font-size: 14px;
}

.story-container .content .img-container img {
    display: block;
    width: calc(100% - 3px);
    height: 100%;
    object-fit: cover;
    border-radius: 20%;
}

.mainButton {
    padding: 10px !important;
}

body,html {
    background: var(--tg-theme-secondary-bg-color);
    color: var(--tg-theme-text-color);
    padding: 0;
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}

* {
    color: var(--tg-theme-text-color);
}

.text-danger {
	color: #dc3545 !important;
}

.text-success {
	color: #28a745 !important;
}

.MuiListItem-root {
    background-color: var(--tg-theme-bg-color);
}

button.ios-style-button {
    cursor: pointer;
    display: inline-block;
    padding: 6px 12px;
    background-color: #0098EA;
    color: white;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

button.ios-style-button:active {
    background-color: #0098EA;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
    transform: scale(0.98);
}

button.ios-style-button:active {
    background-color: #0098EA;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
    transform: scale(0.98);
}

button.ios-style-button:hover {
    background-color: #0098EA;
}

button.ios-style-button:focus {
    outline: none; /* Убираем стандартное выделение */
}

input.ios-style-input {
    display: block;
    width: 100%;
    padding: 12px 16px;
    background-color: var(--tg-theme-bg-color);
    color: var(--tg-theme-text-color);
    border-radius: 10px;
    font-size: 17px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    border: 0;
}

input.ios-style-input:focus {
    box-shadow: 0px 0px 3px rgba(0, 122, 255, 0.5);
    outline: none;
}

input.ios-style-input::placeholder {
    color: #8e8e93;
    opacity: 1;
}

.w-100 {
    width: 100%;
}

.comfortaa {
    font-family: "Comfortaa", sans-serif !important;
    font-optical-sizing: auto;
    font-weight: <weight>;
    font-style: normal;
}

.MuiSvgIcon-root, .MuiTypography-root {
    color: var(--tg-theme-text-color);
}

.MuiDivider-root {
    border-color: var(--tg-theme-secondary-bg-color) !important;
}

.d-flex {
    display: flex !important;
}

.align-items-center {
    align-items: center !important;
}

.webappzGradient {
    background: rgb(226,65,70);
    background: linear-gradient(90deg, rgb(223, 24, 31) 0%, rgba(222,27,33,1) 70%);
}

.btn-sm {
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	line-height: 1.5;
	border-radius: 0.2rem;
}

.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
	padding-right: 0.375rem;
	padding-left: 0.375rem;
}

.justify-content-center {
    justify-content: center;
}

input, .MuiFormLabel-root {
    color: var(--tg-theme-text-color) !important;
}

.blur-overlay-referral {
    position: absolute;
    width: 100%;
    height: 400px;
    z-index: 2;
    background: linear-gradient(to bottom, rgba(158,247,192,0.8), rgba(1,145,69,0)); /* Эффект перехода */
    filter: blur(40px);
    pointer-events: none;
}